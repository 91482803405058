var jquery = require("jquery");
window.$ = window.jQuery = jquery; 

import './css/main.scss'

import * as THREE from 'three'
import WOW from 'wow.js'
import { TimelineMax, Linear } from 'gsap/gsap-core'

import particle from "./textures/particles/1.png"
import sphereHeight from "./textures/sphere/Sci-fi_Metal_Mesh_001_height.png"
import sphereOpacity from "./textures/sphere/Sci-fi_Metal_Mesh_001_opacity.jpg"

new WOW().init();

$( document ).ready(function() {
    $('#navbar').addClass('animated fadeInDown')

    sphereAnim.restart()
});


/**
 * Base
 */

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Textures
 */
const loadingManager = new THREE.LoadingManager()
const textureLoader = new THREE.TextureLoader(loadingManager)
const particleTexture = textureLoader.load(particle)

const heightTexture = textureLoader.load(sphereHeight)
const alphaTexture = textureLoader.load(sphereOpacity)

heightTexture.repeat.x = 8
heightTexture.repeat.y = 8
heightTexture.wrapS = THREE.RepeatWrapping;
heightTexture.wrapT = THREE.RepeatWrapping;


/**
 * Particles
 */
// Geometry
const params = {
    count: 6000,
    length: 100,
    size: 0.05,
    spacing: 0.212,
    insideColour: '#A1E748',
    outsideColour: '#5CF1FF',
    waveDepth: 0.8,
    waveDepthBuffer: 0.8,
    cameraPosX: 16.65,
    cameraPosY: 10,
    cameraPosZ: 10.05,
    cameraRotateY: 1.5,
    sphereColour: '#0FD42C',
    spherePosY: 10,
}


let geometry = null
let material = null
let particles = null


const generateParticles = () => {

    /**
     * Destroy old particles
     */
    if (particles !== null) {
        geometry.dispose()
        material.dispose()
        scene.remove(particles)
    }

    geometry = new THREE.BufferGeometry()

    const positions = new Float32Array(params.count * 3)
    const colours = new Float32Array(params.count * 3)

    const insideColour = new THREE.Color(params.insideColour)
    const outsideColour = new THREE.Color(params.outsideColour)


    for (let i = 0; i < params.count; i++) {

        const i3 = i * 3

        // Position
        positions[i3] = (i % params.length) * params.spacing 
        positions[i3 + 1] = 0
        positions[i3 + 2] = Math.ceil(i / params.length) * params.spacing 


        // Colour 
        const mixedColour = insideColour.clone()
        mixedColour.lerp(outsideColour, i / params.count)

        colours[i3    ] = mixedColour.r
        colours[i3 + 1] = mixedColour.g
        colours[i3 + 2] = mixedColour.b
    }

    geometry.setAttribute(
        'position',
        new THREE.BufferAttribute(positions, 3)
    )

    geometry.setAttribute(
        'color',
        new THREE.BufferAttribute(colours, 3)
    )

    // Material
    material = new THREE.PointsMaterial({
        map: particleTexture,
        size: params.size,
        sizeAttenuation: true,
        transparent: true,
        depthWrite: false,
        blending: THREE.AdditiveBlending,
        vertexColors: true,
    })

    // Points 
    particles = new THREE.Points(geometry, material)
    scene.add(particles)

}

generateParticles()

/**
 * Objects
 */

let sphere = null
let sphereDepth = -0.8


const generateSphere = () => {

    // Material
    const sphereMaterial = new THREE.MeshStandardMaterial({
        displacementMap: heightTexture,
        displacementScale: sphereDepth,
        alphaMap: alphaTexture,
        transparent: true,
    })

    sphereMaterial.normalScale.set(1, 1)
    sphereMaterial.metalness = 0.7
    sphereMaterial.roughness = 1
    sphereMaterial.color = new THREE.Color(0xFFFFFF)


    // Objects
    sphere = new THREE.Mesh(
        new THREE.SphereGeometry(0.5, 32, 32),
        sphereMaterial
    )
    sphere.position.x = 15
    sphere.position.y = params.spherePosY
    sphere.position.z = 10

    sphere.rotation.z = Math.PI + 0.5
    sphere.scale.set(0.01, 0.01, 0.01)

    sphereMaterial.needsUpdate = true

    scene.add(sphere)
}

generateSphere()

const vertices = [];
const noOfParticles = 1000

for ( let i = 0; i < noOfParticles; i ++ ) {

    const size = 1000

	const x = THREE.MathUtils.randFloatSpread( size );
	const y = THREE.MathUtils.randFloatSpread( size );
	const z = THREE.MathUtils.randFloatSpread( size );

	vertices.push( x, y, z );

}

const planeGeometry = new THREE.BufferGeometry();
planeGeometry.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );




const plane = new THREE.Mesh(
    planeGeometry,
    new THREE.PointsMaterial( { color: 0x57e8c5 } )
)

plane.position.set(15, 10, 10)
plane.rotation.y = 1.5
plane.material.metalness = 0.2
plane.material.roughness = 1
plane.material.opacity = 0.015
plane.material.depthWrite = false,
plane.material.transparent = true

scene.add(plane)


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
    documentHeight: $(document).height(),
    windowHeight: $(window).height()
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    sizes.documentHeight = $(document).height(),
    sizes.windowHeight = $(window).height()

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)

// Pos3
camera.position.x = 15.67
camera.position.y = 9.67
camera.position.z = 9.98

camera.rotation.x = 0.75
camera.rotation.y = 0.93
camera.rotation.z = -0.83

let cameraPosition = 'home'

scene.add(camera)


/**
 * Mouse + Scroll
 */
const mouse = new THREE.Vector2()

window.addEventListener('mousemove', (event) => {

    mouse.x = (event.clientX / sizes.width * 2) - 1
    mouse.y = - (event.clientY / sizes.height) * 2 + 1

})

let allowScrolling = true
let allowWaveMove = true

var scrollTop = $(window).scrollTop()
var scrollPercent = (scrollTop / (sizes.documentHeight - sizes.windowHeight));
var scrollBuffer = scrollPercent

window.addEventListener('scroll', () => {

    scrollTop = $(window).scrollTop()
    const scrollPercentCalc = (scrollTop / (sizes.documentHeight - sizes.windowHeight))
    scrollPercent = scrollPercentCalc <= 1 ? scrollPercentCalc : 1

    if (cameraPosition == 'home' || cameraPosition == 'four pillars') {
        if (scrollPercent > 0.90) {
            cameraPosition = 'four pillars'
            allowWaveMove = true
        }
        else {
            cameraPosition = 'home'
            allowWaveMove = false
            params.waveDepth = 0.8
        }
    
    }
})


/**
 * Lights
 */

const ambientLight = new THREE.AmbientLight(0xffffff, 0.1)
scene.add(ambientLight)

// 5CF1FF Blue
const pointLight = new THREE.PointLight(0x0FD42C, 0.5)
pointLight.position.x = 15
pointLight.position.y = -15
pointLight.position.z = -15
pointLight.intensity = 2.5
scene.add(pointLight)

// 0FD42C Green
const pointLight2 = new THREE.PointLight(0x0FD42C, 0.5)
pointLight2.position.x = 17.5
pointLight2.position.y = 17
pointLight2.position.z = 21
pointLight2.intensity = 3.5
scene.add(pointLight2)


/**
 * Renderer
 */

const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.setClearColor( 0xffffff, 0.20);
scene.background = new THREE.Color(0x000000)


/**
 *  Animations
 */
let whiteBackground = new THREE.Color(0xffffff)
let blackBackground = new THREE.Color(0x000000)

const sphereAnim = new TimelineMax({paused: true});
sphereAnim.to(sphere.scale, 1.5, {x:1, y: 1, z: 1,  ease: "sin.inOut"}, 0)
// sphereAnim.to(sphere.scale, 1.5, {x:1, y: 1, z: 1,  ease: "back.out(1)"}, 0)

const waveChange = new TimelineMax({paused: true});
waveChange.to(params, 0.01, {waveDepth: 0, ease: "sine.inOut"})

const cameraToFourPillars = new TimelineMax({paused: true});
cameraToFourPillars.to(camera.position, 1.5, {x: 19.58,y: 0.66, z: 11.24, ease: "sine.inOut"})
cameraToFourPillars.to(camera.rotation, 1.5, {x:0, y: 1, z:0, ease: "sine.inOut"}, 0)
cameraToFourPillars.to(plane.material, 1, {opacity: 0.015, ease:Linear.easeNone }, 0)
cameraToFourPillars.to(scene.background, 1, {r: blackBackground.r, g: blackBackground.g, b: blackBackground.b, ease:Linear.easeNone }, 0.5)

const cameraToConsulting = new TimelineMax({paused: true});
cameraToConsulting.to(camera.position, 1.5, {x:11.385, z: 7.012, ease: "sine.inOut"})
cameraToConsulting.to(camera.rotation, 1.5, {x: -Math.PI/2, y: 0, ease: "sine.inOut"}, 0)
cameraToConsulting.to(camera.position, 1.5, {y: 8.84, ease: "sine.inOut"},0.5)
cameraToConsulting.to(params, 0.5, {waveDepth: 0.2, ease: "sine.inOut"},0.5)

const cameraToSoftware = new TimelineMax({paused: true});
cameraToSoftware.to(params, 1, {waveDepth: 0.2, ease: "elastic.out(100, 5)"})
cameraToSoftware.to(camera.rotation, 2, {x:2, y: 4,  ease: "sine.inOut"}, 0)
cameraToSoftware.to(params, 1, {waveDepth: 5, ease: "elastic.easeOut(1, 0.3)"}, 0.5)
cameraToSoftware.to(camera.position, 1, {x: 17, ease: "back.inOut(1.7)"},0.5)


const cameraToCarrier = new TimelineMax({paused: true});
cameraToCarrier.to(params, 2, {spacing: 0.1, waveDepth: 3, ease:"back.inOut(1)"}, 0.5)
cameraToCarrier.to(camera.position, 2, {x: 23, y: 1, z: 15, ease:"sine.inOut"}, 0.5)
cameraToCarrier.to(camera.rotation, 2, {x: -0.2, y: 1.2, z: 0.2, ease:"sine.inOut"}, 0.5)


const cameraToTactical = new TimelineMax({paused: true});
cameraToTactical.to(params, 1, {spacing: 0.12, waveDepth: 0.5, ease:"sine.inOut"}, 1)
cameraToTactical.to(camera.position, 2, {x: 8, y: 2, z: 9, ease:"sine.inOut"}, 0)
cameraToTactical.to(camera.rotation, 2, {x: 0.5, y: 1, z: 0.5, ease:"sine.inOut"}, 0)


const cameraToTheTeam = new TimelineMax({paused: true});
cameraToTheTeam.to(params, 1, {waveDepth: 0.2, ease: "elastic.out(100, 5)"})
cameraToTheTeam.to(camera.position, 2, {x: 19.58, y: -4, z: 11.24,  ease: "sine.inOut"}, 0)
cameraToTheTeam.to(camera.rotation, 2, {y:2,  ease: "sine.inOut"}, 0)
cameraToTheTeam.to(params, 1, {waveDepth: 0.8, ease: "elastic.easeOut(1, 0.3)"}, 0.5)
cameraToTheTeam.to(scene.background, 1, {r: whiteBackground.r, g: whiteBackground.g, b: whiteBackground.b, ease:Linear.easeNone }, 0.5)
cameraToTheTeam.to(plane.material, 1, {opacity: 0.05, ease:Linear.easeNone }, 0.5)


const cameraToContact = new TimelineMax({paused: true})
cameraToContact.to(camera.position, 2, {x:12, y: -20,  ease: "sine.inOut"}, 0)
cameraToContact.to(camera.rotation, 2, {y:2,  ease: "sine.inOut"}, 0)
cameraToContact.to(scene.background, 1, {r: blackBackground.r, g: blackBackground.g, b: blackBackground.b, ease:Linear.easeNone }, 0.5)
cameraToContact.to(plane.material, 1, {opacity: 0.05, ease:Linear.easeNone }, 0.5)


/**
 * Animate
 */
const clock = new THREE.Clock()


const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update sphere
    if (cameraPosition == 'home') {

        sphere.rotation.y = mouse.x * 0.8 + Math.PI + 0.4
        sphere.rotation.x = (Math.sin(elapsedTime * 0.25) - 0.5)

        sphere.material.displacementScale = mouse.y * 0.20 * (Math.sin(elapsedTime))
    }  

    // Update particles
    const waveDepthBufferDistance = params.waveDepth - params.waveDepthBuffer
    params.waveDepthBuffer += 0.1 * waveDepthBufferDistance

    for (let i = 0; i < params.count; i++) {

        const i3 = i * 3

        // Position
        const x = geometry.attributes.position.array[i3]
        const z = geometry.attributes.position.array[i3 + 2]

        geometry.attributes.position.array[i3] = (i % params.length) * params.spacing 
        geometry.attributes.position.array[i3 + 2] = Math.ceil(i / params.length) * params.spacing 

        switch (cameraPosition) {
            case 'consulting':
                geometry.attributes.position.array[i3 + 1] = Math.sin(((x - 11.385 )**2 + (z - 7.012)**2 + 5) * 0.8 + (elapsedTime * 2)) * 1 * params.waveDepthBuffer
                break;

            case 'tactical':
                geometry.attributes.position.array[i3 + 1] = Math.sin(((x - 11.385 )**2 + (z - 7.012)**2 + 1) * 0.1 + (elapsedTime * 0.05)) * 20 * params.waveDepthBuffer
                break;

            default:

                geometry.attributes.position.array[i3 + 1] = (Math.sin(elapsedTime + x) + Math.cos(elapsedTime + z)) * params.waveDepthBuffer

        }

        if (allowWaveMove) {
            params.waveDepth = Math.sin(params.waveDepthBuffer + mouse.y) * 0.2 + 0.2
        }


    }

    geometry.attributes.position.needsUpdate = true


    // Update scroll position
    if (allowScrolling == true) {

        const scrollBufferDistance = scrollPercent - scrollBuffer
        scrollBuffer += 0.05 * scrollBufferDistance

        camera.position.x = 15.67 + (3.91 * scrollBuffer)
        camera.position.y = 9.67 + (-9.01 * scrollBuffer) 
        camera.position.z = 10.34 + (0.9 * scrollBuffer) 
        
        camera.rotation.x = 0.75 + (-0.75 * scrollBuffer) 
        camera.rotation.y = 0.93 + (0.07 * scrollBuffer) 
        camera.rotation.z = -0.83 + (0.84 * scrollBuffer)

        // Update particle spacing
        params.spacing =  -0.2 + (0.43 * scrollBuffer)
    }


    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()

// On click functions

$('#consultingBtn').on('click', () => {
    if (cameraPosition != 'moving') {
        $("#mainContent").disableScroll();
        cameraPosition = 'moving'
        changeParticlesColour('#FF00FF', '#5CF1FF')
        setTimeout(() => {
            cameraPosition = 'consulting'
        }, 500)
    
        allowWaveMove = false
        allowScrolling = false
        waveChange.restart()
        cameraToConsulting.restart()
        toggleOverlay('consulting', 'fadeInLeftOverlay')
    }
})

$('#softwareBtn').on('click', () => {
    if (cameraPosition != 'software') { 
        $("#mainContent").disableScroll();
        cameraPosition = 'software'
        allowScrolling = false
        cameraToSoftware.restart()
        toggleOverlay('software', 'fadeInLeftOverlay')
    }
})

$('#carrierBtn').on('click', () => {
    if (cameraPosition != 'carrier') { 
        $("#mainContent").disableScroll();
        cameraPosition = 'carrier'
        allowScrolling = false
        allowWaveMove = false
        cameraToCarrier.restart()
        toggleOverlay('carrier', 'fadeInLeftOverlay')
    }
})

$('#tacticalBtn').on('click', () => {
    if (cameraPosition != 'moving') { 
        $("#mainContent").disableScroll();
        cameraPosition = 'moving'
        setTimeout(() => {
            cameraPosition = 'tactical'
        }, 1000)
    
        allowWaveMove = false
        allowScrolling = false
        waveChange.restart()
        cameraToTactical.restart()

        toggleOverlay('tactical', 'fadeInLeftOverlay')
    }
})

$('.returnBtn').on('click', (e) => {

    let pillarSection = $(e.target).val()

    $("#mainContent").enableScroll();

    switch (pillarSection) {
        case 'consulting':
            waveChange.restart()
            cameraToConsulting.reverse()
            changeParticlesColour(params.insideColour, params.outsideColour)
            toggleOverlay(pillarSection, 'fadeInLeftOverlay')
            setTimeout(() => {
                cameraPosition = 'four pillars'
                allowWaveMove = true
            }, 600)
            setTimeout(() => {
                allowScrolling = true
            }, 2000)
            break;

        case 'software':
            cameraPosition = 'four pillars'
            cameraToSoftware.reverse()
            toggleOverlay(pillarSection, 'fadeInLeftOverlay')
            setTimeout(() => {
                allowScrolling = true
                allowWaveMove = true
            }, 2000)
            break;

        case 'carrier':
            cameraPosition = 'four pillars'
            cameraToCarrier.reverse()
            toggleOverlay(pillarSection, 'fadeInLeftOverlay')
            setTimeout(() => {
                allowScrolling = true
                allowWaveMove = true
            }, 2000)
            break;

        case 'tactical':
            waveChange.restart()
            cameraToTactical.reverse()
            toggleOverlay(pillarSection, 'fadeInLeftOverlay')
            setTimeout(() => {
                cameraPosition = 'four pillars'
                allowWaveMove = true
                allowScrolling = true
            }, 2000)
            break; 

    }
    
})


$('.meetTheTeamBtn').on('click', () => {

    if (cameraPosition != 'meetTheTeam') {

        $("#mainContent").disableScroll();

        switch(cameraPosition) {
            case 'contact':
                cameraPosition = 'meetTheTeam'
                allowScrolling = false
                allowWaveMove = false
                cameraToTheTeam.invalidate()
                cameraToTheTeam.play(0)
                toggleOverlay('contact', 'fadeInUpOverlay', false)
                toggleOverlay('meetTheTeam', 'fadeInUpOverlay', false)
                $('#navbar').css('color', 'black')
                $('.navbar-toggler span').css('background-color', 'black')
                
                break;

            case 'home':
            case 'four pillars':
                cameraPosition = 'meetTheTeam'
                allowScrolling = false
                allowWaveMove = false
                cameraToTheTeam.invalidate()
                cameraToTheTeam.play(0)
                toggleOverlay('meetTheTeam', 'fadeInUpOverlay')
                $('#navbar').css('color', 'black')
                $('.navbar-toggler span').css('background-color', 'black')
                break;
    
            default:
                changeParticlesColour(params.insideColour, params.outsideColour)
                toggleOverlay(cameraPosition, 'fadeInLeftOverlay', false)
                toggleOverlay('meetTheTeam', 'fadeInUpOverlay', false)
                cameraPosition = 'meetTheTeam'
                allowScrolling = false
                allowWaveMove = false
                cameraToTheTeam.invalidate()
                cameraToTheTeam.play(0)
                
                $('#navbar').css('color', 'black')
                $('.navbar-toggler span').css('background-color', 'black')
                break;
    
        }
    }
})

$('.servicesBtn').on('click', () => {
    
    if (cameraPosition != 'four pillars') {

        $("#mainContent").enableScroll();

        switch(cameraPosition) {
            case 'meetTheTeam':
                cameraPosition = 'four pillars'
                cameraToFourPillars.invalidate()
                cameraToFourPillars.play(0)
                toggleOverlay('meetTheTeam', 'fadeInUpOverlay')
                $('#navbar').css('color', 'white')
                $('.navbar-toggler span').css('background-color', 'white')
                setTimeout(() => {
                    allowScrolling = true
                    allowWaveMove = true
                }, 2000)

                break;

            case 'contact':
                cameraPosition = 'four pillars'
                cameraToFourPillars.invalidate()
                cameraToFourPillars.play(0)
                toggleOverlay('contact', 'fadeInUpOverlay')
                $('#navbar').css('color', 'white')
                $('.navbar-toggler span').css('background-color', 'white')
                setTimeout(() => {
                    allowScrolling = true
                    allowWaveMove = true
                }, 2000)
                break;

            case 'home':
                cameraPosition = 'four pillars'
                cameraToFourPillars.invalidate()
                cameraToFourPillars.play(0)
                $('#navbar').css('color', 'white')
                $('.navbar-toggler span').css('background-color', 'white')
                setTimeout(() => {
                    allowScrolling = true
                    allowWaveMove = true
                }, 2000)
                break;

            default:
                toggleOverlay(cameraPosition, 'fadeInLeftOverlay')
                changeParticlesColour(params.insideColour, params.outsideColour)
                cameraPosition = 'four pillars'
                cameraToFourPillars.invalidate()
                cameraToFourPillars.play(0)
                $('#navbar').css('color', 'white')
                $('.navbar-toggler span').css('background-color', 'white')
                setTimeout(() => {
                    allowScrolling = true
                    allowWaveMove = true
                }, 2000)
                break;

        }
    }
})

$('.contactBtn').on('click', () => {

    if (cameraPosition != 'contact') {

        $("#mainContent").disableScroll();

        switch (cameraPosition) {
            case 'meetTheTeam':
                toggleOverlay(cameraPosition, 'fadeInUpOverlay', false)
                toggleOverlay('contact', 'fadeInUpOverlay', false)
                $('#navbar').css('color', 'white')
                $('.navbar-toggler span').css('background-color', 'white')
                cameraPosition = 'contact'
                cameraToContact.invalidate()
                cameraToContact.play(0)
                break;

            case 'home':
            case 'four pillars':
                toggleOverlay('contact', 'fadeInUpOverlay')
                $('#navbar').css('color', 'white')
                $('.navbar-toggler span').css('background-color', 'white')
                cameraPosition = 'contact'
                allowScrolling = false
                cameraToContact.invalidate()
                cameraToContact.play(0)
                break;
    
            default:
                changeParticlesColour(params.insideColour, params.outsideColour)
                toggleOverlay(cameraPosition, 'fadeInLeftOverlay', false)
                toggleOverlay('contact', 'fadeInUpOverlay', false)
                $('#navbar').css('color', 'white')
                $('.navbar-toggler span').css('background-color', 'white')
                cameraPosition = 'contact'
                allowScrolling = false
                cameraToContact.invalidate()
                cameraToContact.play(0)
                break;
    
        }    
    }
})

$('.consultingOption').on('click', (e) => { 

    let currentContent = $('.selected').val()
    let chosenTitle = $(e.target).text()
    let chosenContent = $(e.target).val()

    $('.selected').removeClass('selected')
    $(e.target).addClass('selected')

    $('#consultingTitle').html(`${chosenTitle}<span class='segeroDot'>.</span>`)

    $(`#${currentContent}`).hide()
    $(`#${chosenContent}`).show()

    $(`#consultingBlock`).addClass('fadeInLeftOverlay')

    setTimeout(() => {
        $(`#consultingBlock`).removeClass('fadeInLeftOverlay')
    }, 1000)

})





// Functions 

$.fn.disableScroll = function() {
    window.oldScrollPos = $(window).scrollTop();

    $(window).on('scroll.scrolldisabler',function ( event ) {
       $(window).scrollTop( window.oldScrollPos );
       event.preventDefault();
    });
};

$.fn.enableScroll = function() {
    $(window).off('scroll.scrolldisabler');
};


function toggleOverlay(id, animation, fadeMain=true) {
    if (!$(`#${id}`).hasClass(animation)) {
        $(`#${id}`).show()
        $(`#${id}`).addClass(animation)
    }   else {
        $(`#${id}`).removeClass(animation)
        
        setTimeout(() => {
            $(`#${id}`).hide()
            
        }, 2000)
    }

    // Fade in/out main content
    if (fadeMain) {
        if (!$(`#mainContent`).hasClass('fadeOut')) {

            $(`#mainContent`).addClass('fadeOut')
        }   
        else {
    
            setTimeout(() => {
                $(`#mainContent`).removeClass('fadeOut')
            }, 2000)
            
        }  
    }

}

function changeParticlesColour(colour1, colour2) {

    const insideColour = new THREE.Color(colour1)
    const outsideColour = new THREE.Color(colour2)

    for (let i = 0; i < params.count * 3; i++) {

        const i3 = i * 3

        // Colour 
        const mixedColour = insideColour.clone()
        mixedColour.lerp(outsideColour, i / params.count)

        geometry.attributes.color.array[i3] = mixedColour.r
        geometry.attributes.color.array[i3 + 1] = mixedColour.g
        geometry.attributes.color.array[i3 + 2] = mixedColour.b

    }
    geometry.attributes.color.needsUpdate = true
}


$('#submitBtn').on('click', function(e){
    e.preventDefault();

    $(".form-group").removeClass("has-error");
    $(".help-block").remove();


    var formData = {
        name: $("#name").val(),
        email: $("#email").val(),
        phone: $("#phone").val(),
        message: $("#message").val()
    };

    $.ajax({
        type: 'post',
        url: 'sendMail.php',
        data: formData,
        dataType: "json",
        encode: true,
        success: function(data) {

            if (!data.success) {
                if (data.errors.name) {
                    $("#name-group").addClass("has-error");
                    $("#name-group").append(
                        '<div class="help-block">' + data.errors.name + "</div>"
                    );
                }
        
                if (data.errors.email) {
                    $("#email-group").addClass("has-error");
                    $("#email-group").append(
                        '<div class="help-block">' + data.errors.email + "</div>"
                    );
                }

                if (data.errors.phone) {
                    $("#phone-group").addClass("has-error");
                    $("#phone-group").append(
                        '<div class="help-block">' + data.errors.phone + "</div>"
                    );
                }

                if (data.errors.message) {
                    $("#message-group").addClass("has-error");
                    $("#message-group").append(
                        '<div class="help-block">' + data.errors.message + "</div>"
                    );
                }
        

              } else {
                $("form").html(
                  '<div class="alert alert-success">' + data.message + "</div>"
                );
              }
        }
    });
});
 